import gql from "graphql-tag";

const NRQL_RAW_QUERY = gql`
  query NrqlRawQuery($account_id: Int!, $query: String!) {
    platforms {
      new_relic {
        nrql(account_id: $account_id, query: $query) {
          result {
            response
          }
        }
      }
    }
  }
`;

export { NRQL_RAW_QUERY };
