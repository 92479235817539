import { PAGE_REQUEST_DATA } from "../../../../../store/common/page.module";
import NotificationService from "../../../../../core/services/notification.service";
import { NRQL_RAW_QUERY } from "./NrqlQuery.queries";
import { SET_BREADCRUMB } from "../../../../../store/common/breadcrumbs.module";

export default {
  apollo: {
    nrql() {
      return {
        query: NRQL_RAW_QUERY,
        variables() {
          return {
            account_id: this.form.accountId,
            query: this.form.query
          };
        },
        update(data) {
          let response = data?.platforms?.new_relic?.nrql?.result?.response;
          if (response) {
            response = JSON.parse(response);
            let headers = [];
            if (response.length) {
              for (const key of Object.keys(response[0])) {
                headers.push({ value: key, text: key });
              }
            } else {
              headers = [];
              response = [];
            }
            this.tableNrql.headers = headers;
            return response;
          }
        },
        watchLoading(isLoading) {
          this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
        },
        error(error) {
          console.error(error);
          NotificationService.error(error);
        },
        skip: true
      };
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Group", route: "/group/navigation-menu" },
      { title: "New Relic Query Language" }
    ]);
  },
  data() {
    return {
      form: {
        pollInterval: 0,
        query: "SELECT * FROM Log",
        accountId: 2751202,
        shouldPoll: false
      },
      tableNrql: {
        headers: []
      }
    };
  },
  methods: {
    exportLogsToCsv() {
      throw Error("TO DO");
    },
    queryData() {
      this.$apollo.queries.nrql.skip = false;
      if (this.form.shouldPoll) {
        this.$apollo.queries.nrql.startPolling(this.form.pollInterval * 1000);
      } else {
        this.$apollo.queries.nrql.stopPolling();
      }
      this.$apollo.queries.nrql
        .refetch()
        .finally(() => (this.$apollo.queries.nrql.skip = false));
    }
  }
};
